// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.


require("@rails/ujs").start()
require("turbolinks").start()
require("@rails/activestorage").start()
// require("spotlight_search")
require("daterangepicker")
require("channels")
require("jgrowl")
require('moment')
require('bootstrap-datepicker')

import jQuery from 'jquery';
window.$ = jQuery
window.jQuery = jQuery
import 'jquery/dist/jquery.min';
import 'bootstrap/dist/js/bootstrap'
import 'select2';
import 'bootstrap-datepicker/dist/js/bootstrap-datepicker.js';
import '@fortawesome/fontawesome-free/js/all.js';

const importAll = (r) => r.keys().forEach(r);
// importAll(require.context('../application/coffee_scripts/', true, /\.coffee$/));
importAll(require.context('../application/stylesheets/', true, /\.scss$/));
require.context('../application/images', true)
// require.context('../application/coffee_scripts', true)
// require.context('../application/stylesheets', true)